import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import OnlineClaimServicesModal from 'components/modals/OnlineClaimServices/OnlineClaimServices';
import ClaimHistoryTable from 'components/sections/claim/ClaimHistoryTable';
import { Formik } from 'formik';
import AppStore from 'img/raster/AppStore/comp';
import PhoneHand from 'img/raster/PhoneHand/comp';
import PlayStore from 'img/raster/PlayStore/comp';
import {
    hasBenefitAccount,
    hasCover,
    hasStaffBlock,
} from 'libs/booleanHelpers';
import gtagEvent from 'libs/gtagEvent';
import { allPersons } from 'libs/helpers';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useReducer } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    setClaimPaymentsModalVisible,
    setOnlineClaimServicesVisible,
} from 'store';
import * as Yup from 'yup';

const schema = Yup.object({
    claimant: Yup.string().required(),
    claimType: Yup.string().required(),
    period: Yup.string().required(),
});

const initialState = {
    claimant: 'all',
    claimType: 'all',
    period: 'all',
};

function reducer(_, action) {
    switch (action.type) {
        case 'filters_changed':
            return action.payload;
        default:
            throw new Error();
    }
}

const ClaimButton = ({ membership, product }) => {
    const dispatch = useDispatch();

    if (membership && product) {
        if (hasStaffBlock(membership)) {
            return (
                <StatusNotificationCard status="error">
                    Access to online claiming is not available on your
                    membership. Please contact us for more information.
                </StatusNotificationCard>
            );
        }
        if (hasCover(product, 'extras')) {
            return (
                <>
                    <Link
                        to="/member/claim/start"
                        style={{
                            display: 'inline',
                        }}
                        onClick={(event) => {
                            if (!hasBenefitAccount(membership)) {
                                event.preventDefault();
                            } else {
                                gtagEvent({
                                    screen: 'claims',
                                    action: 'newclaim',
                                    label: 'User initiated a new claim from claims page',
                                });
                            }
                        }}>
                        <Button
                            variant="new-claim"
                            disabled={!hasBenefitAccount(membership)}>
                            <div className="py-2">
                                <MdAddCircle size={20} /> Start a New Claim
                            </div>
                        </Button>
                    </Link>
                    {!hasBenefitAccount(membership) && (
                        <StatusNotificationCard status="error">
                            It looks like we don&apos;t have a bank account to
                            pay your claim into. Click{' '}
                            <a
                                className="alert-link"
                                href=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(
                                        setClaimPaymentsModalVisible(true),
                                    );
                                }}>
                                here
                            </a>{' '}
                            to set up your account and once completed you will
                            be able to submit your claim.
                        </StatusNotificationCard>
                    )}
                </>
            );
        }
    }

    return null;
};

const ClaimsPage = () => {
    const membership = useSelector((state) => state.membership);
    const allClaims = useSelector((state) => state?.claims?.claims);
    const patients = allPersons(membership);
    const claimSpecialities = useSelector((state) => state.claimSpeciality);
    const product = useSelector((state) => state.product);
    const onlineClaimServicesVisible = useSelector(
        (state) => state.ui.onlineClaimServicesVisible,
    );

    /** NOTE: there is a name clash between 'useReducer' and 'useDispatch' */
    /** NOTE: do not remove the underscore on _dispatch here */
    const _dispatch = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialState);

    const nativeFormSubmit = (formData) => {
        gtagEvent({
            screen: 'claims',
            action: 'filterhistory',
            label: 'User filtered their claims history',
        });
        dispatch({ type: 'filters_changed', payload: formData });
    };

    const filteredClaims = useMemo(() => {
        const filters = {};

        if (state.claimant !== 'all') {
            filters.PersonId = parseInt(state.claimant);
        }

        let filteredResults = _.filter(allClaims, filters);

        if (state.period !== 'all') {
            filteredResults = filteredResults.filter((claim) => {
                switch (state.period) {
                    case 'all':
                        return true;
                    case 'thismonth':
                        return moment(claim.ServiceDate).isSame(
                            moment(),
                            'month',
                        );
                    case 'lastmonth':
                        return moment(claim.ServiceDate).isSame(
                            moment().subtract(1, 'months'),
                            'month',
                        );
                    case 'last3months':
                        return (
                            moment(claim.ServiceDate).isSame(
                                moment().subtract(1, 'months'),
                                'month',
                            ) ||
                            moment(claim.ServiceDate).isSame(
                                moment().subtract(2, 'months'),
                                'month',
                            ) ||
                            moment(claim.ServiceDate).isSame(
                                moment().subtract(3, 'months'),
                                'month',
                            )
                        );
                    case 'thisyear':
                        return moment(claim.ServiceDate).isSame(
                            moment(),
                            'year',
                        );
                }
            });
        }

        return filteredResults;
    }, [state, allClaims]);

    return (
        <main className="p-4">
            <div className="row">
                <div className="col-12 col-sm-7">
                    <Card className="p-4">
                        <h1 className={'text-primary text-uppercase'}>
                            Claim History
                        </h1>

                        <Formik
                            validationSchema={schema}
                            onSubmit={nativeFormSubmit}
                            initialValues={{
                                claimant: 'all',
                                claimType: 'all',
                                period: 'all',
                            }}>
                            {({ handleSubmit, handleChange, values }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group
                                            as={Col}
                                            controlId="formClaimant">
                                            <Form.Label>Claimant</Form.Label>
                                            <Form.Control
                                                name="claimant"
                                                as="select"
                                                onChange={handleChange}
                                                value={values.claimant}>
                                                <option value="all">All</option>
                                                {patients
                                                    ? patients.map(
                                                          (patient, idx) => (
                                                              <option
                                                                  key={idx}
                                                                  value={
                                                                      patient
                                                                          .Person
                                                                          .PersonId
                                                                  }>
                                                                  {
                                                                      patient
                                                                          .Person
                                                                          .GivenName
                                                                  }
                                                              </option>
                                                          ),
                                                      )
                                                    : ''}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            controlId="formClaimType"
                                            style={{
                                                display: 'none',
                                            }}>
                                            <Form.Label>
                                                Type of Claim
                                            </Form.Label>
                                            <Form.Control
                                                value={values.claimType}
                                                as="select"
                                                onChange={handleChange}
                                                name="claimType">
                                                <option value="all">
                                                    All Claims
                                                </option>
                                                {claimSpecialities
                                                    ? claimSpecialities.map(
                                                          (row, idx) => (
                                                              <option
                                                                  key={idx}
                                                                  value={
                                                                      row.ClaimTypeCode
                                                                  }>
                                                                  {
                                                                      row.Description
                                                                  }
                                                              </option>
                                                          ),
                                                      )
                                                    : ''}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            controlId="formPeriod">
                                            <Form.Label>Period</Form.Label>
                                            <Form.Control
                                                value={values.period}
                                                onChange={handleChange}
                                                name="period"
                                                as="select">
                                                <option value="all">
                                                    Last two years
                                                </option>
                                                <option value="thismonth">
                                                    This Month
                                                </option>
                                                <option value="lastmonth">
                                                    Last Month
                                                </option>
                                                <option value="last3months">
                                                    Last 3 Months
                                                </option>
                                                <option value="thisyear">
                                                    This Year
                                                </option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group className="d-flex align-items-end">
                                            <Button
                                                variant="primary-dark-1"
                                                type="submit">
                                                Filter
                                            </Button>
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                            )}
                        </Formik>

                        <ClaimHistoryTable data={filteredClaims} />
                    </Card>
                </div>
                <div className="col-12 col-sm-5 mt-4 mt-sm-0">
                    <Card className="overflow-hidden">
                        <div className="p-4">
                            <h1 className={'text-primary text-uppercase'}>
                                Make a new Claim
                            </h1>

                            <p>
                                The Member Portal offers online claiming for all
                                general claims that meet the following
                                requirements:
                            </p>

                            <ul>
                                <li className={'mb-3'}>
                                    The service provided was within the last two
                                    (2) years
                                </li>
                                <li className={'mb-3'}>
                                    The service falls under one of the service
                                    types listed.{' '}
                                    <Button
                                        onClick={() => {
                                            /** NOTE: there is a name clash between 'useReducer' and 'useDispatch' */
                                            /** NOTE: do not remove the underscore on _dispatch here */

                                            _dispatch(
                                                setOnlineClaimServicesVisible(
                                                    true,
                                                ),
                                            );
                                        }}
                                        variant="font-weight-bold text-primary-dark-1 p-0 m-0">
                                        <span
                                            style={{
                                                textDecoration: 'underline',
                                            }}>
                                            Click here to check the list.
                                        </span>
                                    </Button>
                                </li>
                                <li className={'mb-3'}>
                                    If your claim does not meet these
                                    requirements please try one of our other{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://unionhealth.com.au/information/using-your-extras/make-claim-extras">
                                        claiming options
                                    </a>{' '}
                                    or download our Smart Claiming App with your
                                    smartphone and submit your claim with just a
                                    few clicks.
                                </li>
                            </ul>

                            <p>
                                Unionhealth requires receipts for claims submitted using this online claiming function. 
                                Online claims will be subject to audits. Please ensure you retain all receipts 
                                for a period of (12) twelve months after submission.
                            </p>

                            <ClaimButton
                                membership={membership}
                                product={product}
                            />
                        </div>

                        <div className="bg-primary mt-5">
                            <div className="no-gutters row">
                                <div className="col-7 p-4">
                                    <p
                                        className="text-white font-weight-ultra"
                                        style={{
                                            fontSize: '21px',
                                            lineHeight: '25px',
                                        }}>
                                        Download our{' '}
                                        <span className="text-primary-dark-1">
                                            Smart Claiming App
                                        </span>{' '}
                                        &amp; submit your claim with just a few
                                        taps.
                                    </p>
                                    <div>
                                        <a
                                            href="https://itunes.apple.com/au/app/tuh-health-fund/id627121894?mt=8"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="mr-3 mt-2 d-inline-block">
                                            <AppStore />
                                        </a>
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.zeroseven.tuhclaiming&hl=en_AU"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="mr-3 mt-2 d-inline-block">
                                            <PlayStore />
                                        </a>
                                    </div>
                                </div>
                                <div
                                    className="col-5 text-right"
                                    style={{
                                        marginTop: '-35px',
                                    }}>
                                    <PhoneHand />
                                </div>
                            </div>
                        </div>
                    </Card>

                    <OnlineClaimServicesModal
                        handleClose={() => {
                            setOnlineClaimServicesVisible(false);
                        }}
                        show={onlineClaimServicesVisible}
                        align={'right'}
                    />
                </div>
            </div>
        </main>
    );
};

export default ClaimsPage;
